import {useEffect,useState,Fragment} from 'react'
import { Card,Checkbox,FormControl,FormLabel,FormGroup,FormControlLabel,Container ,MenuItem,Select,InputLabel,Stack,TextField,Grid,Box } from '@mui/material'
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import {get,post} from 'axios'
import _, { set } from 'lodash'
import {toast} from 'react-toastify'
import * as XLSX from "xlsx";
import axios from 'axios';
import { useSelector } from 'react-redux';
export default function ExportFile() {
    const [state, setState] = useState({loading:false,raw:[],device_id:'',interval:30,params:[],selected:new Set(),devices:[]});
    const [device,setDevice]=useState('')
    const [sensors,setSensors]=useState([]);
    const user = useSelector((state) => state.user);
    const sensorToHeaderMap = {
      pm1:"PM 1 (ug/m3)",
      pm10: "PM 10 (ug/m3)",
      pm25: "PM 2.5 (ug/m3)",
      so2: "SO2 (ppb)",
      no2: "NO2 (ppb)",
      o3:"O3 (ppb)",
      co:"CO (ppb)",
      co2: "CO2 (ppm)",
      temp: "Temp (°C)",
      hum:"Humidity %",
      time: "Time",
      aqi: "Air Quality Index"
    }
    const cell_style = {
      alignment: {
        horizontal: 'center', // Aligns text to the center horizontally
        vertical: 'center', // Aligns text to the center vertically
      },
    };
    // async function fetchSensor(){
    //   try{
    //     const {data}=await axios.get(`${process.env.REACT_APP_BASE_URL}/api/auth/profile`,{params:{device_id:state.device_id}})
    //     console.log('sensors',data)
    //     setState({...state,devices:data.devices});
    //   }catch(e){
    //     setSensors([])
    //     console.log(e.message)
    //   }
    // }
    // useEffect(()=>{
    //   fetchSensor()
    // },[state.device_id])
    async function fetchDevices() {
      const { data } = await get(process.env.REACT_APP_BASE_URL + '/api/users/devices',{params:{page:1,limit:50}});
      setState({
        ...state,
        devices: data.data,
      });
    }
  useEffect(()=>{
    fetchDevices();
  },[])
  useEffect(() => {
    if (state.device_id) {
      const data = (devices.filter((item) => item.device_id == state.device_id))
      setSensors(data.length ? data[0]?.realtime?.map((item) => item) : []);
    }}, [state.device_id]);
  const { selected,devices,interval,raw,device_id } = state;
async function getRawdata(){
      try{
        setState({...state,loading:true,raw:[]})
        const { data } = await post('/api/users/devices/rawdata', {
          device_id: state.device_id,
          interval: state.interval,
          startDateTime: state.start,
          endDateTime: state.stop,
          params: Array.from(state.selected),
        }); 
        // setRaw(data)
        // max 2 decimal
        data.forEach((item) => {
          Object.keys(item).forEach((key) => {
            if (key === 'time') {
              item[key] = new Date(item[key]).toLocaleString();
            }
            else if (key==='aqi') {
              item[key] = parseInt(item[key]);
            }
            else if (!isNaN(item[key])) {
              item[key] = parseFloat(item[key]).toFixed(2);
            }
          });
        });
         const deviceName = devices.filter((item) => item.device_id == state.device_id)[0].label;
        if(data[0]){
          const keys = Object.keys(data[0]).filter(key => key !== "device_id"&&key !== "time");
          keys.unshift("time");
          const excelData = [
            // Title row (merged dynamically later)
            [user.name],
            [`Device Location: ${deviceName}`],
            [`Device ID: ${device_id}`],
            [`Start: ${new Date(state.start).toLocaleString()}`],
            [`Stop: ${new Date(state.stop).toLocaleString()}`],
            [`Interval: ${state.interval} seconds`],
            [],
            // Header row
            keys.map((key) => sensorToHeaderMap[key] || key),
            // Data rows
            ...data.map((item) => keys.map((key) => item[key])),
          ];
          const worksheet = XLSX.utils.aoa_to_sheet(excelData);
          worksheet["!merges"] = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: keys.length - 1 } }, // Merge first row
            { s: { r: 1, c: 0 }, e: { r: 1, c: keys.length - 1 } }, // Merge second row
            { s: { r: 2, c: 0 }, e: { r: 2, c: keys.length - 1 } }, // Merge third row
            { s: { r: 3, c: 0 }, e: { r: 3, c: keys.length - 1 } }, // Merge fourth row
            { s: { r: 4, c: 0 }, e: { r: 4, c: keys.length - 1 } }  // Merge fifth row
          ];
          
          var wscols = [
            {wch:20},
            {wch:20},
            {wch:30},
            {wch:20},{wch:20},
            {wch:20},
            {wch:20},
            {wch:20},{wch:20},
            {wch:20},
            {wch:20},
            {wch:20},{wch:20},
            {wch:20},
            {wch:20},
            {wch:20},{wch:20},
            {wch:20},
            {wch:20},
            {wch:20},{wch:20},
            {wch:20},
            {wch:20},
            {wch:20},{wch:20},
            {wch:20},
            {wch:20},
            {wch:20},
          ];

          worksheet['!cols'] = wscols;
          worksheet['!protect']={password:new Date().toISOString(),formatRows:false }
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");
          XLSX.writeFile(workbook, `${device_id}.xlsx`);
          }
      }catch(e){
        console.log(e.response)
        if(Array.isArray(e?.response?.data?.message))
        e.response.data.message.forEach(item=>toast.error(item))
        else if(e?.response?.data?.message)
        toast.error(e.response.data.message)
        else toast.error(e.message)
      }finally{
        setState({...state,loading:false})
      }
      
    }
  const handleChange = (event) => {
    console.log(event.target.name,event.target.checked,state)
    event.target.checked?selected.add(event.target.name):selected.delete(event.target.name)
    // setState({
    //   ...state,
    //   [event.target.name]: event.target.checked,
    // });
  };

//   const error = [gilad, jason, antoine].filter((v) => v).length !== 1;
  return (
    <Container justifyContent="center" alignItems="center">
      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-required-label">Select Device</InputLabel>
            <Select
              labelId="demo-simple-select-required-label"
              label="Device"
              onChange={(e) => {setState({ ...state, device_id: e.target.value })}}
            >
              {state.devices.map((item) => {
                return <MenuItem value={item.device_id}>{item.label} ({item.device_id})</MenuItem>
              }
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <FormControl fullWidth required>
            <InputLabel>Select Interval</InputLabel>
            <Select
              labelId="demo-simple-select-required-label"
              label="interval"
              onChange={(e) =>setState({ ...state, interval: e.target.value })}
            >
              {[
                { label: '1 Minute', value: 60 },
                { label: '5 Minute', value: 60 * 5 },
                { label: '15 Minutes', value: 60 * 15 },
                { label: '30 Minutes', value: 60 * 30 },
                { label: '1 Hour', value: 60 * 60 },
                { label: '6 Hour', value: 60 * 60 * 6 },
                { label: '12 Hour', value: 60 * 60 * 12 },
                { label: '1 Day', value: 60 * 60 * 24 },
              ].map((item,idx) => (
                <MenuItem key={idx} value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Stack spacing={3} direction="row" fullWidth>
            <TextField
              fullWidth
              id="datetime-local"
              label="Start Date"
              type="datetime-local"
              onChange={(e) => setState({ ...state, start: new Date(e.target.value).toISOString() })}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="datetime-local"
              label="Stop Date"
              type="datetime-local"
              onChange={(e) => setState({ ...state, stop: new Date(e.target.value).toISOString() })}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          {sensors.length ? (
            <FormControl fullWidth component="fieldset" variant="standard">
              <FormLabel component="legend">Select Parameters</FormLabel>
              <Grid container justifyContent="center" alignItems="center">
                {sensors.map((item) => (
                  <FormControlLabel
                    control={<Checkbox onChange={handleChange} name={item._field} value={item._field} />}
                    label={item._field}
                  />
                ))}
              </Grid>
            </FormControl>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            fullWidth
            loading={state.loading}
            loadingPosition="start"
            variant="outlined"
            onClick={getRawdata}
            startIcon={<SaveIcon />}
          >
            {state.loading ? 'Downloading CSV' : 'Download CSV'}
          </LoadingButton>
        </Grid>
      </Grid>
    </Container>
  );
}
