import axios from 'axios';
import { Container, Typography, Grid, Card, Select, MenuItem, FormControl } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import AqiTable from './AqiTable';

import SensorWidget from './SensorWidget';

const name = {
  temp: {
    name: 'Temperature',
    icon: 'carbon:temperature-celsius',
    measurement: { ppb: 1, ppm: 1, µgm: 1, mgm: 1 },
  },
  aqi: { name: 'AQI', icon: 'bxs:face-mask', measurement: { ppb: 1, ppm: 1, µgm: 1, mgm: 1 } },
  pm25: { name: 'PM 2.5', icon: 'fluent:weather-duststorm-20-filled', measurement: { ppb: 1, ppm: 1, µgm: 1, mgm: 1 } },
  pm10: { name: 'PM 10', icon: 'iconoir:oil-industry', measurement: { ppb: 1, ppm: 1, µgm: 1, mgm: 1 } },
  pm1: { name: 'PM 1', icon: 'emojione:factory', measurement: { ppb: 1, ppm: 1, µgm: 1, mgm: 1 } },
  hum: { name: 'Humidity', icon: 'entypo:drop', measurement: { ppb: 1, ppm: 1, µgm: 1, mgm: 1 } },
  so2: { name: 'SO2', icon: 'emojione:factory', measurement: { ppb: 1, ppm: 0.001, µgm: 2.621, mgm: 0.002621 } },
  no2: { name: 'NO2', icon: 'ps:feedburner', measurement: { ppb: 1, ppm: 0.001, µgm: 1.881, mgm: 0.001881 } },
  co: { name: 'CO', icon: 'mdi:molecule-co', measurement: { ppb: 1, ppm: 0.001, µgm: 1.145, mgm: 0.001145 } },
  co2: { name: 'CO2', icon: 'iwwa:co2', measurement: { ppb: 1, ppm: 0.001, µgm: 1.799, mgm: 0.001799 } },
  o3: {
    name: 'O3',
    icon: 'simple-icons:vectorlogozone',
    measurement: { ppb: 1, ppm: 0.001, µgm: 1.962, mgm: 0.001962 },
  },
  nh3: { name: 'NH3', icon: 'mdi:ammonia', measurement: { ppb: 1, ppm: 0.001, µgm: 0.771, mgm: 0.000771 } },
  hcl: { name: 'HCL', icon: 'mdi:acid', measurement: { ppb: 1, ppm: 0.001, µgm: 1.639, mgm: 0.001639 } },
  lux: { name: 'LUX', icon: 'mdi:lightbulb-on', measurement: { ppb: 1, ppm: 0.001, µgm: 1.639, mgm: 0.001639 } },
  sound: { name: 'Sound', icon: 'mdi:volume-high', measurement: { ppb: 1, ppm: 0.001, µgm: 1.639, mgm: 0.001639 } },
};

const INTERVAL = 1000 * 3; // 3 sec

export default function RealTime({ device }) {
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [sensorValues, setSensorValues] = useState([]);
  const [measurement, setMeasurement] = useState('ppb');
  const [interval, setInter] = useState();
  useEffect(() => {
    if (user.authenticated&&device) {
      fetchData();
    }
    return undefined;
  }, [device, measurement]);

  async function fetchData() {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/users/devices/lastdata`, {
        params: { device_id: device, unit: measurement },
      });
      setSensorValues(data.sort((a, b) => a._field.localeCompare(b._field)));
    } catch (e) {
      toast.error(e.message);
      setSensorValues([]);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    clearInterval(interval);
    if (device) {
      fetchData();
      const interval = setInterval(() => fetchData(), 1000 * 30); // 30 sec
      setInter(interval);
      return () => clearInterval(interval);
    }
  }, [device, measurement]);

  const handleChange = (event) => {
    setMeasurement(event.target.value);
  };
  return (
    <>
      <Container sx={{ p: 1, mt: 1, justifyContent: 'end' }}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Card>
            <Grid container spacing={3} p={3}>
              <Grid container direction="row" justifyContent="space-between" alignItems="center" p={3}>
                <>
                  <Typography variant="h4">
                    Realtime Data <br />
                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                      {sensorValues.length ? new Date(sensorValues[0]._time).toLocaleString() : null}
                    </Typography>
                  </Typography>
                  <Typography variant="h6">Device: {device}</Typography>
                </>
                <FormControl sx={{ m: 1, minWidth: 80 }}>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={measurement}
                    onChange={handleChange}
                    autoWidth
                  >
                    <MenuItem value={'ppb'}>PPB</MenuItem>
                    <MenuItem value={'ppm'}>PPM</MenuItem>
                    <MenuItem value={'ug/m3'}>µg/m³</MenuItem>
                    <MenuItem value={'mg/m3'}>mg/m³</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {sensorValues.map((item, idx) => {
                return (
                  <SensorWidget
                    key={idx}
                    title={name[item._field] ? name[item._field].name : item._field}
                    field={item._field}
                    total={item?._value}
                    measurement={measurement}
                    unit={item.unit}
                    icon={name[item._field]?.icon ?? 'simple-icons:vectorlogozone'}
                  />
                );
              })}
              <Grid item xs={12}>
                <AqiTable />
              </Grid>
            </Grid>
          </Card>
        )}
      </Container>
    </>
  );
}
