import PropTypes from 'prop-types';
// material
import { alpha, styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { Box, Stack, AppBar, Toolbar, IconButton, Paper } from '@mui/material';
// components
import Iconify from '../../components/Iconify';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import languages from '../../utils/language.json';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const language = useSelector((state) => state.user.language);
  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        {/* <Searchbar /> */}

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="" spacing={{ xs: 0.5, sm: 1.5, lg: 3 }}>
          <Stack direction="row" justifyContent="space-evenly">
            <Paper>
              <img src="/static/cdac.jpeg" alt="logo" width="120" height="60" />
            </Paper>
            <Paper>
              <img src="/static/company2.jpeg" alt="logo" width="120" height="40" />
            </Paper>
            <Paper>
              <img src="/static/company1.jpeg" alt="logo" width="120" height="40" />
            </Paper>
          </Stack>
          <LanguagePopover />
          {/* <NotificationsPopover /> */}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
